<template>
    <div class="view-container">
        <div class="header">
            <div class="login-box">
                  <div class="title">鉴定中心考试系统</div>
                   <CountDown :timeObj="timeObj" />
                  <div class="user-info">
                    <img :src="userAvatar ? userAvatar : require('../../../assets/images/user_img.png')" alt="">
                    <div class="user-name">{{userName}}</div>
                    <i @click="logoutBtn" class="iconfont logout-btn">&#xe60c;</i>
                </div>
            </div>
        </div>
        <div class="main">
            <el-scrollbar class="main-content">
                  <div class="exam-name">{{examName}}</div>
                   <div class="exam-type-box">
                       <div class="exam-type" v-for="item in examTypeList" @click="toExamDetail(item)">
                            <div class="type-img">
                                <img :src="item.imgSrc" alt="">
                            </div>
                            <span>{{item.name}}</span>
                        </div>
                        <div class="exam-type" style="height: 0;padding: 0;margin: 0"></div>
                        <div class="exam-type" style="height: 0;padding: 0;margin: 0"></div>
                   </div>
                    <div class="no-data" style="display: none">
                        暂无考试
                    </div>
            </el-scrollbar>
        </div>
    </div>
</template>

<script>
     import {userLogout , getAuthen} from '@/utils/apis'
     import CountDown from '../../../components/student/CountDown.vue'
     export default {
         name:"authenticate",
         data(){
             return{
                 //中心列表
                 examTypeList:[
                     {
                        name:'理论考试',
                        path:'/student/evaluation/examing/theoryTest/index',
                        imgSrc:require('./../../../assets/images/student/exam-type01.png')
                     }
                 ],
                 //学生名字和学生头像
                 userName:'',
                 userAvatar:'',
                 //页面传参ID
                 examination_id:this.$route.query.id,
                 examName:'',
                 //倒计时
                  timeObj:{
                    begin_time:null,
                    end_time:null,
                  },
                }
            },
         components:{
            CountDown
         },
         mounted() {
            this.role = localStorage.getItem('role');
             if (this.role === '5') {
                this.userName = localStorage.getItem('studentName');
                this.userAvatar = localStorage.getItem('studentAvatar');
             }
             this.getAuthenticatePage()
         },
         methods:{
             //考试跳转
             toExamDetail(item){
                 this.$router.push({
                     path:item.path,
                     query:{
                         id: this.examination_id,
                         exam_name: item.name
                     }
                 })
             },


             //获取主页面信息
             getAuthenticatePage(){
                 let params = {
                     id:this.examination_id
                 }
                 getAuthen(params).then((res)=>{
                    this.examName = res.data.examination_name
                    this.examTypeList[0].name = res.data.paper_name
                    this.timeObj.begin_time = res.data.start_time;
                    this.timeObj.end_time = res.data.end_time;

                 }).catch((err)=>{
                      console.log(err);
                 })
             },

             //退出
             logoutBtn() {
                userLogout().then((res) => {
                    localStorage.clear();
                    this.$router.push('/');
                    this.$message.success(res.msg);
                }).catch((err) => {
                    console.log(err);
                })
            }
         },

     }
</script>


<style scoped lang="scss">
    .view-container {
        width: 100vw;
        height: 100vh;
        min-width: 1200px;
        background: #F7F8FC;
        display: flex;
        flex-direction: column;
        overflow: hidden;

        .header {
            width: 100%;
            height: 60px;
            background: #fff;
            box-shadow: 0 2px 5px 0 rgba(15, 26, 54, 0.05);
            display: flex;
            z-index: 99;
            .login-box {
                width: 100%;
                font-size: 16px;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 0 20px;
                .title {
                    font-size: 18px;
                    font-weight: 400;
                    color: #333;
                }
                img {
                    height: 50px;
                    width: 94px;
                }
                .user-info {
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    img {
                        width: 40px;
                        height: 40px;
                        border-radius: 50%;
                        margin-right: 10px;
                    }
                    .user-name {
                        width: 100px;
                        max-height: 50px;
                        text-align: center;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }

                    .logout-btn {
                        cursor: pointer;
                        margin-left: 20px;
                        color: #2DC079;
                    }
                }
            }
        }

        .main {
            width: 100%;
            height: 1%;
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            background: url("./../../../assets/images/student/student-exam-bj.png") no-repeat center center;
            background-size: cover;
            .main-content{
              max-height: 724px;
              width: 1300px;
              background: url("./../../../assets/images/student/exam-type-bj.png") no-repeat center center;
              background-size: cover;
              display: flex;
              flex-direction: column;
              box-sizing: border-box;
              border-radius: 8px;
              ::v-deep .el-scrollbar__wrap{
                overflow-x: hidden;
                .el-scrollbar__view{
                  padding: 114px 60px 170px;
                }
              }
              .exam-name{
                line-height: 1;
                font-size: 30px;
                font-weight: 500;
                text-align: center;
              }
              .exam-type-box{
                margin-top: 38px;
                display: flex;
                line-height: 1;
                flex-wrap: wrap;
                justify-content: space-between;
                .exam-type{
                  cursor: pointer;
                  margin-top: 40px;
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  justify-content: center;
                  background: #fff;
                  box-shadow: 0px 0px 13px 0px rgba(56, 56, 56, 0.08);
                  border-radius: 8px;
                  box-sizing: border-box;
                  padding: 30px 0 20px;
                  width: 280px;
                  transition: 0.3s;
                  span{
                    font-size: 18px;
                    font-weight: 500;
                    margin-top: 20px;
                  }
                  &:hover{
                    transform:scale(1.1);
                  }
                }
              }
              .no-data{
                margin-top: 38px;
                font-size: 24px;
                text-align: center;
                color: #666;
              }
            }
        }
    }
</style>